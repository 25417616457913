<template>
  <article v-for="post in posts"
           :class="post.type === 'pa_product' ? 'excerpt pa_product type-pa_product ' : 'excerpt page type-page'">
    <div class="featured-image" v-if="post.image_url">
      <template v-if="post.type === 'pa_product'">
        <a :title="post.title" :href="post.product_link">
          <img :src="post.image_url" class="attachment-medium size-medium wp-post-image" width="208" height="305">
        </a>
      </template>
      <template v-else>
        <a href="post.product_link">
          <img :src="post.image_url" class="attachment-medium size-medium wp-post-image" width="208" height="305">
        </a>
      </template>
    </div>
    <template v-if="post.type === 'pa_product'">
      <div class="post-content">
        <header>
          <h2>
            <a :href="post.product_link" v-html="post.title"></a></h2>
        </header>
        <p v-if="post.excerpt" v-html="post.excerpt">

        </p>
        <footer>
          <a :href="post.product_link" :title="'Continue reading ' + post.title"
             class="button">Read more</a>
        </footer>
      </div>
    </template>
    <template v-else>
      <div class="post-content">
        <header>
          <h2>
            <a :href="post.link" v-html="post.title"></a>
          </h2>
        </header>
        <p v-html="post.excerpt">

        </p>
        <a class="button" :class="post.is_external === true ? 'external-button':'internal-button'" :href="post.link"
           :title="'Continue reading ' + post.title" :target="post.is_external === true ? '_blank':'_self'">Read
          More</a>

      </div>
    </template>
  </article>
</template>
<script>
export default {
  props: {
    posts: Array
  },

  data() {
    return {};
  },

  computed: {},
  methods: {}
}
</script>